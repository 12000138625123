body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Fira Code", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff6c9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-title {
  text-align: center;
  margin: 10px !important;
}

.normal-text {
  text-align: center;
  margin: 0px !important;
}

.social-media {
  margin-bottom: 25px;
}

.bold-text {
  text-align: center;
  font-weight: 750;
}

.app-icon {
  margin: 10px;
  margin-top: 25px;
  margin-bottom: 50px;
}

#root {
  height: 100%;
}

/**
* Page link css and animation
**/
.page-link {
  display: table-cell;
  position: relative;
  padding: 15px 0;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  cursor: pointer;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

.page-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #000000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.page-link a {
  color: black;
  text-decoration: none;
}

.page-link:hover:after {
  width: 100%;
  left: 0;
}

/* Normal web views */
@media screen and (min-width: 601px) {
  .header-title {
    font-size: 56px;
  }
}

/* Mobile web views */
@media screen and (max-width: 600px) {
  .header-title {
    font-size: 32px;
  }
}
